<template>
  <div class="newsWrap">
    <div class="mainCom w1200 clearfix">
      <div class="mainComL">
        <menuWX></menuWX>
      </div>
      <div class="mainComR">
        <div class="newsBar mb20" :style="{backgroundImage: 'url('+newsBg+')'}">
          <div class="newsMain" ref="init" :style="{paddingTop: newsMaintop+'px'}">
            <div class="crumbWrap">
              <span class="crumbItem" @click="goOther('/')">首页</span>
              <i class="el-icon-arrow-right"></i>
              <span class="crumbItem" @click="goOther('/news')">新闻列表</span>
              <i class="el-icon-arrow-right"></i>
              <span>新闻详情</span>
            </div>
            <div class="detailWrap">
              <div class="titleWrap txtC">
                <div class="title">{{title}}</div>
                <div class="time">发布日期：{{time}}</div>
              </div>
              <div class="des" v-html="des"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import menuWX from '@/components/Menu'
  import downItemWX from '@/views/downLoad/item'
  import axios from "axios";
  export default {
    name: 'news',
    components:{
      menuWX,
      downItemWX,
    },
    data(){
      return {
        newsBg:require('@/assets/imgs/news/newsBg.png'),
        id:this.$route.query.newsId,
        title:'',
        time:'',
        des:'',
        newsMaintop:0,
      }
    },
    created() {
      this.getDetailData()
    },
    mounted() {
      document.documentElement.scrollTop = 0
    },
    updated(){
      if (this.$refs.init.offsetHeight>2000) {
        this.newsMaintop = 50
      }else if (this.$refs.init.offsetHeight>1000 && this.$refs.init.offsetHeight<2000) {
        this.newsMaintop = 20
      }

    },
    methods: {
      getDetailData(){        // 获取详情页初始数据
        let data = {
          id:this.id,
        }
        axios.post('/news/getNewsDetail', data).then((res) => {
          if(res.data.state == 200){
            this.title = res.data.data.title;
            this.time = res.data.data.pubDate;
            this.des = res.data.data.content;
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },
      goOther(url){
        this.$router.push({
          path:url,
        })
      },
    }
  }
</script>

<!--<style>
  .newsWrap .detailWrap p{
    text-indent: 40px;
    padding:14px 0;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    line-height: 29px;
  }
</style>-->
<style lang="less" scoped>
  .newsWrap{
    .newsBar{
      padding:40px 40px 50px 40px;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .newsMain{
        .crumbWrap{
          padding:20px;
          span{
            font-size: 18px;
            color: #666;
          }
          i{
            margin: 0 8px;
            font-size: 18px;
            color: #333333;
          }
          .crumbItem{
            color: #000000;
            cursor: pointer;
          }
        }
        .detailWrap{
          padding: 0 63px;
          .titleWrap{
            padding:30px 0 8px;
            border-bottom: 1px solid #999999;
            .title{
              margin-bottom: 10px;
              font-size: 24px;
              font-weight: 500;
              color: #D22190;
              line-height: 28px;
            }
            .time{
              height: 22px;
              font-size: 16px;
              font-weight: 400;
              color: #999999;
              line-height: 19px;
            }
          }
          .des{
            padding: 20px 0;
          }
        }
      }
    }
  }
</style>
